import React from "react"
/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import HeaderBlockColor from "../components/headerBlockColor"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HeaderBlockColor />
    <Box
      sx={{
        mx: 3,
        background:
          "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
        transform: "rotate(-3deg) skew(-3deg) !important",
        py: 3,
        px: 3,
        position: "relative",
        mt: "-100px",
        mb: 3,
        display: "inline-block",
        "& h1": {
          color: "white",
          transform: "rotate(3deg) skew(3deg) !important",
        },
      }}
    >
      <h1>404: Not Found</h1>
    </Box>
    <Box
      sx={{
        mx: [3, 4, 4],
        "& .lead": {
          fontSize: 5,
          color: "text",
        },
      }}
    >
      <p className="lead">
        This page doesn't exist. Please use the navigation above to navigate to
        a page that does!!
      </p>
    </Box>
  </Layout>
)

export default NotFoundPage
